.cardListingScroll-wrapper {
  display: grid;
  margin: 0;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.cardListingScroll-info-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
  width: 320px;
}

.cardListItem-main-card {
  width: 300px;
  height: 400px;
}

.cardItem-img {
  height: 200px;
  width: 287px;
  object-fit: cover;
}

.home {
  padding: 0;
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.splash {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/colleges.jpeg");
  height: calc(45vh);
  background-size: cover;
  background-position-y: 30%;
  padding-top: 265px;
  margin-top: -128px;
}
