.personCard {
  display: inline-block;
  margin: 8px;
}

.toolCard {
  width: 250px;
  height: 290px;
  margin: 8px;
  display: inline-block;
}

.cardAction {
  width: 250px;
  height: 290px;
}

.cardImage {
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  height: 175px;
  object-fit: contain !important;
}

.innerCard {
  margin: 16px;
}
