.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html,
body {
  background-color: rgb(235, 235, 235);
  height: 100%;
}

.navbar {
  background-color: blue;
}

div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-container {
  padding-bottom: 16px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paper {
  background-color: rebeccapurple;
}

.paperTitle {
  padding: 8px;
  text-align: center;
}

.card {
  border-radius: 16px !important;
}

.socialStack {
  padding: 8px;
  padding-bottom: 24px;
}

.centerText {
  text-align: center;
}

.modelTitle {
  margin-top: 24px !important;
  margin-left: 8px !important;
}

.instanceCard {
  margin: 8px;
}

.highlighter {
  background-color: #1875d157;
  border-radius: 8px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.highlighter-chip {
  color: white;
  background-color: #37a3b1;
  border-radius: 8px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
