.cityModelCard {
  margin: 8px;
  display: inline-block;
  height: 600px;
  width: 400px;
}

.cityActionArea {
  height: 600px;
  width: 400px;
}
